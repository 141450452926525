import { Transaction } from '../transactions/Transaction';

export interface TransactionForm {
    values: FormValues;
    options: Options;
    dialogOpen: boolean;
    currentTransactionId?: number;
    currentTransaction?: Transaction;
    isError: boolean;
    errorMessages: string[];
    serverTs?: number;
    status: FormStatus;
}

export interface FormValues {
    productId: string;
    currency: string;
    strona: string;
    currencyDate: string;
    validTime: Date | null;
    price: string;
    endDate: Date | null;
    amount: string;
    account1: string;
    account2: string;

    clientId: string;
    userLogin: string;
}

export interface OfferTimes {
    minTime: Date;
    maxTime: Date;
}

export interface Options {
    productTypes: ProductTypesEntity[];
    currencies: string[];
    sides: SidesEntity[];
    currencyDates: string[];
    productPrecision?: number;
    maxValidTimes: string;
    validOfferTimes?: OfferTimes;
    currencyDate: string;
    maxDate: Date;
    term: string;
    account1Curr: string;
    account2Curr: string;
    accounts1: string[];
    accounts2: string[];
    submitAllowed: boolean;
}

export interface ProductTypesEntity {
    key: string;
    value: string;
    expirationTime?: number | null;
}

export enum Product {
    FX_SPOT = '3',
    FX_ORDER = '6',
    DEPOZYT_NEG = '7'
}

export namespace Product {
    export function isFx(productId: string) {
        return productId === Product.FX_SPOT || productId === Product.FX_ORDER;
    }
}

export enum Side {
    K = 'buy',
    S = 'sell'
}

export enum SideSymbol {
    K = 'K',
    S = 'S'
}

export enum TransactionStatus {
    W = 'W',
    K = 'K',
    D = 'D',
    F = 'F',
    A = 'A'
}

export enum FormStatus {
    SENDING_IN_PROGRESS = 'sending_in_progress',
    IN_PREPARATION = 'in_preparation',
    RESPONDED = 'responded'
}

export namespace TransactionStatus {
    export function isEligibleForCancellation(status?: TransactionStatus | string) {
        return status === TransactionStatus.W || status === TransactionStatus.K || status === TransactionStatus.D;
    }
}

export interface SidesEntity {
    key: string;
    value: string;
}

export interface ValidationResult {
    valid: boolean;
    errorMessages: string[];
}

export interface ValueChange {
    valType: keyof FormValues;
    value: FormValues[keyof FormValues];
}

export function newTransactionFormValues() {
    return {
        productId: '',
        currency: '',
        strona: '',
        currencyDate: '',
        validTime: null,
        price: '',
        endDate: null,
        amount: '',
        account1: '',
        account2: '',
        clientId: '@clientId',
        userLogin: '@login'
    };
}

export function newTransactionForm(): TransactionForm {
    return {
        values: newTransactionFormValues(),
        options: {
            productTypes: [],
            currencies: [],
            sides: [],
            currencyDates: [],
            currencyDate: '',
            maxValidTimes: '',
            maxDate: new Date(),
            term: '',
            account1Curr: '',
            account2Curr: '',
            accounts1: [],
            accounts2: [],
            submitAllowed: true
        },
        dialogOpen: false,
        isError: false,
        errorMessages: [],
        status: FormStatus.IN_PREPARATION
    }
}
