import { TableType, TransactionType } from "../transactions/Transaction";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks";
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatAmount, millisToDateString, millisToDateTimeString } from "../../services/utils";
import { Side } from "../exchange/TransactionForm";
import { StyledTable } from "../transactions/transactionsList";
import { DoneTransaction } from "./startTypes";

interface DoneTransactionsTableProps {
    transactions: DoneTransaction[];
    type?: TableType;
}

export default function DoneTransactionsTable(
    { transactions, type = TableType.TODAYS }: Readonly<DoneTransactionsTableProps>
) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <TableContainer component={Paper}>
            <StyledTable size='medium' aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>{t('ref_number')}</TableCell>
                        <TableCell align='center'>{t('type')}</TableCell>
                        <TableCell align='center'>{t('currency')}</TableCell>
                        <TableCell align='center'>{t('side')}</TableCell>
                        <TableCell align='center'>{t('amount')}</TableCell>
                        <TableCell align='center'>{t('end_date')}</TableCell>
                        <TableCell align='center'>{t('price')}</TableCell>
                        {type === TableType.FX_ORDER &&
                            <TableCell align='center'>{t('offer_timeout')}</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.map((row) => (
                        <TableRow key={row.refNr}>
                            <TableCell align='center'>{row.refNr}</TableCell>
                            <TableCell align='center'>{row.type}</TableCell>
                            <TableCell align='center'>{row.currency}</TableCell>
                            <TableCell align='center'>{t(Side[row.bidOffer as keyof typeof Side])}</TableCell>
                            <TableCell align='center' style={{whiteSpace: 'nowrap'}}>{formatAmount(row.amount)}</TableCell>
                            <TableCell align='center'>{millisToDateString(row.currencyDate)}</TableCell>
                            <TableCell align='center'>{
                                formatAmount(row.price, (TransactionType.DEPOZYT_NEG===row.type ? 2 : 4))
                            }</TableCell>
                            {type === TableType.FX_ORDER &&
                                <TableCell align='center'>{millisToDateTimeString(row.expireDate)}</TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    );
}