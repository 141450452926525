import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { CommonSelectControl, CommonTextControl } from '../common/CommonFormControl';
import {
    handleAmountChange,
    handleChangeEvent,
    handleCurrencyChangeEvent,
    handleSubmit,
    StyledButtonWithSpinner,
} from "./tranactionForm";

export default function FxSpotInputSet() {
    const { t } = useTranslation();

    const currencies = useAppSelector(state => state.form.options.currencies)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const sides = useAppSelector(state => state.form.options.sides)
        .map(item => <MenuItem key={item.key} value={item.key}>{t(item.value)}</MenuItem>);

    const endDates = useAppSelector(state => state.form.options.currencyDates)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const accounts1 = useAppSelector(state => state.form.options.accounts1)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const accounts2 = useAppSelector(state => state.form.options.accounts2)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const submitAllowed = useAppSelector(state => state.form.options.submitAllowed);

    const currency = useAppSelector(state => state.form.values.currency);
    const side = useAppSelector(state => state.form.values.strona);
    const currencyDate = useAppSelector(state => state.form.values.currencyDate);
    const amount = useAppSelector(state => state.form.values.amount);
    const account1 = useAppSelector(state => state.form.values.account1);
    const account2 = useAppSelector(state => state.form.values.account2);
    const account1Curr = useAppSelector(state => state.form.options.account1Curr);
    const account2Curr = useAppSelector(state => state.form.options.account2Curr);

    const formStatus = useAppSelector(state => state.form.status);

    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            gap: 1,
            gridTemplateRows: 'auto',
            gridTemplateAreas: `
                'currency strona currencyDate . . . .'
                'amount account1 account2 . . . .'
                'submit . . . . . .'
            `
        }}>
            <CommonSelectControl
                name='currency'
                items={currencies}
                callback={handleCurrencyChangeEvent}
                value={currency}
            />
            <CommonSelectControl
                name='strona'
                items={sides}
                callback={handleChangeEvent}
                value={side}
            />
            <CommonSelectControl
                name='currencyDate'
                items={endDates}
                callback={handleChangeEvent}
                value={currencyDate}
            />
            <CommonTextControl
                required
                name='amount'
                callback={handleAmountChange}
                value={amount}
            />
            <CommonSelectControl
                translatedName={`${t('account')} ${account1Curr}`}
                name='account1'
                items={accounts1}
                callback={handleChangeEvent}
                value={account1}
            />
            <CommonSelectControl
                translatedName={`${t('account')} ${account2Curr}`}
                name='account2'
                items={accounts2}
                callback={handleChangeEvent}
                value={account2}
            />
            <StyledButtonWithSpinner
                onClick={handleSubmit}
                label={t('send_transaction')}
                submitAllowed={submitAllowed}
                formStatus={formStatus}
            />
        </Box>
    );
}
