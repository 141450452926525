import { styled } from '@mui/material/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { TableType, Transaction, TransactionType } from './Transaction';
import { formatAmount, millisToDateString, millisToDateTimeString } from '../../services/utils';
import { Side, TransactionStatus } from "../exchange/TransactionForm";
import { getTransaction, openDialog, productChanged } from "../exchange/transactionFormSlice";
import { useAppDispatch } from "../../app/hooks";
import ConfirmWindow from "../exchange/confirmWindow";
import Countdown from "react-countdown";
import { CountdownRenderProps } from "react-countdown/dist/Countdown";

export const StyledTable = styled(Table)(({ theme }) => `
    min-width: 450;
    border-radius: ${theme.shape};
`);

interface TransactionsTableProps {
    transactions: Transaction[];
    type?: TableType;
}

export default function TransactionsTable(
    { transactions, type = TableType.DEFAULT }: Readonly<TransactionsTableProps>
) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    return <>
        <TableContainer component={Paper}>
            <StyledTable size='medium' aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>{t('ref_number')}</TableCell>
                        {TableType.isDefaultOrHistory(type) &&
                            <>
                                <TableCell align='center'>{t('transaction_timestamp')}</TableCell>
                                <TableCell align='center'>{t('allowed_person')}</TableCell>
                            </>
                        }
                        <TableCell align='center'>{t('type')}</TableCell>
                        <TableCell align='center'>{t('currency')}</TableCell>
                        <TableCell align='center'>{t('side')}</TableCell>
                        <TableCell align='center'>{t('amount')}</TableCell>
                        {TableType.isDefaultOrHistory(type) &&
                            <TableCell align='center'>{t('start_date')}</TableCell>
                        }
                        <TableCell align='center'>{t('end_date')}</TableCell>
                        {TableType.isDefaultOrHistory(type) &&
                            <TableCell align='center'>{t('term')}</TableCell>
                        }
                        <TableCell align='center'>{t('price')}</TableCell>
                        {TableType.isDefaultOrHistory(type) &&
                            <TableCell align='center'>{t('status')}</TableCell>
                        }
                        {TableType.isDefaultOrFxOrder(type) &&
                            <TableCell align='center'>{t('offer_timeout')}</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.map((row) => (
                        <TableRow
                            key={row.nrReferencyjny}
                            onClick={TableType.isDefault(type) ? () => handleClick(row.nrReferencyjny, row.produkt) : undefined}
                            sx={TableType.isDefault(type) ? { '&:hover': { cursor: 'pointer' } } : undefined}
                        >
                            <TableCell align='center'>{row.nrReferencyjny}</TableCell>
                            {TableType.isDefaultOrHistory(type) &&
                                <>
                                    <TableCell align='center'>{TableType.isHistory(type) ?
                                        millisToDateTimeString(row.czasDataZawarciaTransakcji as number) :
                                        row.czasDataZawarciaTransakcji
                                    }</TableCell>
                                    <TableCell align='center'>{row.klientNazwaOperatora}</TableCell>
                                </>
                            }
                            <TableCell align='center'>{row.typTransakcji}</TableCell>
                            <TableCell align='center'>{row.waluta}</TableCell>
                            <TableCell align='center'>{t(Side[row.strona as keyof typeof Side])}</TableCell>
                            <TableCell align='center'>{formatAmount(row.kwota)}</TableCell>
                            {TableType.isDefaultOrHistory(type) &&
                                <TableCell align='center'>{millisToDateString(row.dataWaluty)}</TableCell>
                            }
                            <TableCell align='center'>{millisToDateString(row.dataZakonczenia)}</TableCell>
                            {TableType.isDefaultOrHistory(type) &&
                                <TableCell align='center'>{row.termin}</TableCell>
                            }
                            <TableCell align='center' sx={
                                TableType.isDefault(type) && row.statusTransakcji === TransactionStatus.F ?
                                    { fontWeight: 'bold' } : {}
                            }>{formatAmount(row.cenaOprocentowanie, (TransactionType.DEPOZYT_NEG === row.typTransakcji ? 2 : 4))}</TableCell>
                            {TableType.isDefaultOrHistory(type) &&
                                <TableCell align='center' sx={
                                    TableType.isDefault(type) && row.statusTransakcji === TransactionStatus.F ?
                                        { color: 'primary.main', textTransform: 'uppercase', fontWeight: 'bold' } : {}
                                }>
                                    {row.statusTransakcjiOpis}
                                </TableCell>
                            }
                            {TableType.isDefaultOrFxOrder(type) &&
                                <TableCell align='center'>{row.statusTransakcji === TransactionStatus.F ?
                                    <Countdown
                                        date={row.czasWygasniecia}
                                        renderer={renderer}
                                    /> :
                                    millisToDateTimeString(row.czasWygasniecia)
                                }</TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
        <ConfirmWindow />
    </>;

    function handleClick(transactionId: string, productId: string) {
        dispatch(getTransaction(transactionId));
        dispatch(productChanged(productId));
        dispatch(openDialog(true));
    }

    function renderer({ formatted: { minutes, seconds }}: CountdownRenderProps) {
        return (
            <Typography align='center' variant='body2' sx={{ fontWeight: 'bold' }}>
                {minutes}:{seconds}
            </Typography>
        );
    }
}
