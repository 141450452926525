import { Box, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { CommonDateControl, CommonSelectControl, CommonTextControl } from '../common/CommonFormControl';
import {
    handleAmountChange,
    handleChangeEvent,
    handleCurrencyChangeEvent,
    handlePickerChange,
    handleSubmit,
    StyledButtonWithSpinner
} from "./tranactionForm";
import { store } from "../../app/store";
import { getTerm } from "./transactionFormSlice";

export default function DepozytNegInputSet() {
    const { t } = useTranslation();

    const currencies = useAppSelector(state => state.form.options.currencies)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const currencyDates = [useAppSelector(state => state.form.options.currencyDate)]
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const accounts1 = useAppSelector(state => state.form.options.accounts1)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const submitAllowed = useAppSelector(state => state.form.options.submitAllowed);
    const maxDate = useAppSelector(state => state.form.options.maxDate);

    const currency = useAppSelector(state => state.form.values.currency);
    const currencyDate = useAppSelector(state => state.form.values.currencyDate);
    const endDate = useAppSelector(state => state.form.values.endDate);
    const amount = useAppSelector(state => state.form.values.amount);
    const account1 = useAppSelector(state => state.form.values.account1);
    const term = useAppSelector(state => state.form.options.term)

    const formStatus = useAppSelector(state => state.form.status);

    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            gap: 1,
            gridTemplateRows: 'auto',
            gridTemplateAreas: `
                'currency currencyDate endDate term . . .'
                'amount account1 . . . . .'
                'submit . . . . . .'
            `
        }}>
            <CommonSelectControl
                name='currency'
                items={currencies}
                callback={handleCurrencyChangeEvent}
                value={currency}
            />
            <CommonSelectControl
                name='currencyDate'
                label='startDate'
                items={currencyDates}
                callback={handleChangeEvent}
                value={currencyDate}
            />
            <CommonDateControl
                name='endDate'
                value={endDate}
                callback={handleEndDateChange}
                minDate={new Date()}
                maxDate={maxDate}
                shouldDisableDate={date => date.getDay() % 6 === 0}
            />
            <Typography sx={{
                gridArea: 'term',
                alignSelf: 'center',
            }}>
                {term}
            </Typography>
            <CommonTextControl
                required
                name='amount'
                callback={handleAmountChange}
                value={amount}
            />
            <CommonSelectControl
                name='account1'
                items={accounts1}
                callback={handleChangeEvent}
                value={account1}
            />
            <StyledButtonWithSpinner
                onClick={handleSubmit}
                label={t('send_transaction')}
                submitAllowed={submitAllowed}
                formStatus={formStatus}
            />
        </Box>
    );
}

function handleEndDateChange(value: Date) {
    handlePickerChange('endDate', value)
    store.dispatch(getTerm(value));
}
